import bannerImg from '../../assets/homebanner.webp';

import { FormattedMessage } from 'react-intl';

export default function TeamComponent() {
  return (
    <div className="home-div">
      <img
        src={bannerImg}
        className="home-banner-img"
      />

      <div className="home-description">
        <h2 className="home-title">
          <FormattedMessage
            id="team.title"
            defaultMessage="Welcome to Riviera by ATA"
          />
        </h2>

        <p className="home-text">
          <FormattedMessage
            id="team.description1"
            defaultMessage="Riviera by ATA is, above all, a wonderful family venture led by Anaïs, Arthur, and Thomas. Inspired by our love for our native region, Provence, we created this company to showcase local craftsmanship and its unique treasures."
          />
        </p>

        <p className="home-text">
          <FormattedMessage
            id="team.description2"
            defaultMessage="Our mission? To select authentic products for you, all rooted in traditional expertise and meeting exacting standards of quality and humanity. We work with passionate artisans who share our values: respect for tradition, a love for well-crafted work, and a sincere commitment to people."
          />
        </p>

        <p className="home-text">
          <FormattedMessage
            id="team.description3"
            defaultMessage="Each product you discover at Riviera by ATA tells a story: the story of a sun-drenched land, a rich heritage, and exceptional craftsmanship. We are proud to support and champion the men and women who continue to uphold the excellence of Provence."
          />
        </p>

        <p className="home-text">
          <FormattedMessage
            id="team.description4"
            defaultMessage="By choosing Riviera by ATA, you choose authenticity, local flair, and family spirit. We hope that our selection will bring you a little of the magic and charm of Provence."
          />
        </p>
      </div>
    </div>
  );
}
