import React, {
  useState,
  useEffect,
  createContext,
  useRef,
  useCallback,
} from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import 'slick-carousel/slick/slick.css'; // Import default slick styles
import 'slick-carousel/slick/slick-theme.css'; // Slick theme CSS
import './App.css';
import './styles/desktop/style.css';
import './styles/mobile/style.css';
import { API_ROUTES, SOCKET_ENDPOINT } from './data/constants';
import GalleryPage from './pages/gallerypage';
import ProductPage from './pages/productpage';
import ProducersPage from './pages/producerspage';
import ProducerPage from './pages/producerpage';
import LoginPage from './pages/loginpage';
import LoggedIn from './components/login/loggedin';
import Banner from './components/banner/banner';
import Footer from './components/footer/footer';
import AccountPage from './pages/accountpage';
import CheckoutPage from './pages/checkoutpage';
import BasketPage from './pages/basketpage';
import io from 'socket.io-client';
import ShippingPage from './pages/shippingpage';
import AddressPage from './pages/addresspage';
import CheckoutComplete from './pages/checkoutcomplete';
import VerifyEmailPage from './pages/verifyemailpage';
import ReactGA from 'react-ga4';
import CookieConsent from './components/cookieconsent/cookieconsent';
import ForgotPasswordPage from './pages/forgotpwdpage';
import SignUpOkay from './pages/signupok';
import ScrollToTop from './components/common/scrolltotop';
import CgvPage from './pages/conditionspage';
import PrivacyPage from './pages/privacypage';
import logo from './assets/logo.webp';
import Modal from './components/common/modale';
import ResetPasswordPage from './pages/resetpwdpage';
import BillingAddressPage from './pages/billingaddresspage';
import LegalNoticePage from './pages/legalnoticepage';
import NotFoundPage from './pages/notfoundpage';
import HomePage from './pages/teampage';
import TeamPage from './pages/teampage';
import CategoriesPage from './pages/categoriespage';
import CategoryPage from './pages/categorypage';
import ContactPage from './pages/contactpage';
import sendKeyEvent from './components/googleanalytics/keyeventscomponent';

const userLanguage = navigator.language || navigator.userLanguage;
const defaultLocale = userLanguage.startsWith('fr') ? 'fr' : 'en';

export const AuthContext = createContext();

function loadGtag() {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=G-KB0KVNXF16`;
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', 'G-KB0KVNXF16', {
      cookie_flags: 'SameSite=None;Secure',
      debug_mode: true,
    });

    // Initialiser ReactGA après que le script gtag.js soit chargé
    ReactGA.initialize('G-N48JGPFF2H');
  };
}

function initializeAnalytics() {
  loadGtag();
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [producers, setProducers] = useState([]);
  const [locale, setLocale] = useState(defaultLocale);
  const [messages, setMessages] = useState({});
  const [socket, setSocket] = useState(null);
  const [user, setUser] = useState({});
  const [shippingCost, setShippingCost] = useState(null);
  const [shipping, setShipping] = useState([]);
  const [shippingId, setShippingId] = useState('');
  const [confirmBasketProducts, setConfirmBasketProducts] = useState([]);
  const [chosenShippingAddress, setChosenShippingAddress] = useState({});
  const [chosenBillingAddress, setChosenBillingAddress] = useState({});
  const [promoCode, setPromoCode] = useState({});
  const [appliedPromoCode, setAppliedPromoCode] = useState(false);
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [billingAddresses, setBillingAddresses] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [basketTaxes, setBasketTaxes] = useState([]);
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  const [performanceCookies, setPerformanceCookies] = useState(false);
  const [units, setUnits] = useState([]);
  const [basket, setBasket] = useState([]);
  const [orders, setOrders] = useState([]);
  const [freeDelivery, setFreeDelivery] = useState(false);
  const [freeDeliveryAmount, setFreeDeliveryAmount] = useState(100);
  const [alcohol, setAlcohol] = useState(false);
  const [certifiedLegal, setCertifiedLegal] = useState(false);
  const cartIconRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    async function FetchData() {
      try {
        LoggedIn({
          setIsLoggedIn,
          setUser,
          setShippingAddresses,
          setBillingAddresses,
          setLocale,
          locale,
        });

        const [
          productRes,
          catRes,
          producerRes,
          shippingRes,
          taxRes,
          unitRes,
          freeDeliveryRes,
        ] = await Promise.all([
          fetch(API_ROUTES.GET_PRODUCTS),
          fetch(API_ROUTES.GET_CATEGORIES),
          fetch(API_ROUTES.GET_PRODUCERS),
          fetch(API_ROUTES.GET_SHIPPING_METHODS),
          fetch(API_ROUTES.GET_TAXES),
          fetch(API_ROUTES.GET_UNITS),
          fetch(API_ROUTES.GET_FREE_DELIVERY),
        ]);

        const productsData = await productRes.json();
        setProducts(productsData);

        const categoriesData = await catRes.json();
        setCategories(categoriesData);

        const producersData = await producerRes.json();
        setProducers(producersData);

        const shippingData = await shippingRes.json();
        setShipping(shippingData);

        const taxData = await taxRes.json();
        setTaxes(taxData);

        const unitData = await unitRes.json();
        setUnits(unitData);

        const freeDeliveryData = await freeDeliveryRes.json();
        const freeDeliveryAmountData = freeDeliveryData.amount;
        setFreeDeliveryAmount(freeDeliveryAmountData);
      } catch (error) {
        console.error('Erreur lors de la récupération des données : ', error);

        throw new Error(error);
      }
    }

    FetchData();
  }, []);

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname.startsWith('/fr/') && locale !== 'fr') {
      setLocale('fr');
    } else if (pathname.startsWith('/en/') && locale !== 'en') {
      setLocale('en');
    } else if (
      (pathname === '/fr' && locale !== 'fr') ||
      (pathname === '/en' && locale !== 'fr')
    ) {
      if (isLoggedIn && socket) {
        socket.emit('update-user', { locale: locale });
      }
      setLocale(pathname.substring(1));
    }

    sendKeyEvent({
      eventCategory: 'Navigation',
      eventAction: 'Browse',
      eventLabel: 'Page Load',
      eventValue: pathname,
    });
  }, [location.pathname]);

  useEffect(() => {
    document.documentElement.lang = locale;
    import(`./locales/${locale}.json`)
      .then((messages) => {
        setMessages(messages.default);
      })
      .catch((error) => {
        console.error(
          'Erreur lors du chargement des messages localisés : ',
          error
        );
        throw new Error(error);
      });
  }, [locale]);

  useEffect(() => {
    const preferencesString = localStorage.getItem('cookiePreferences');
    const preferences = JSON.parse(preferencesString);

    if (!preferences) {
      setShowCookieBanner(true);
    } else if (preferences.analyticsCookies === true) {
      setPerformanceCookies(true);
    }
  }, []);

  const handleCookieAcceptance = (preferences) => {
    setShowCookieBanner(false);

    if (preferences.analyticsCookies === true) {
      setPerformanceCookies(true);
    }
  };

  useEffect(() => {
    if (isLoggedIn && !socket) {
      const token = localStorage.getItem('token');
      const newSocket = io(SOCKET_ENDPOINT, {
        path: '/socket.io',
        query: { token },
      });
      setSocket(newSocket);
    } else if (socket && !isLoggedIn) {
      socket.disconnect();
      setSocket(null);
      setUser({});

      LoggedIn({
        setIsLoggedIn,
        setUser,
        setShippingAddresses,
        setBillingAddresses,
        locale,
      });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (performanceCookies === true) {
      initializeAnalytics();
    }
  }, [performanceCookies]);

  useEffect(() => {
    const storedProducts = JSON.parse(localStorage.getItem('products') || '[]');
    const tempBasketExists =
      Array.isArray(storedProducts) && storedProducts.length > 0;
    if (user) {
      if (
        !tempBasketExists &&
        user.tempBasket &&
        user.tempBasket.length > 0 &&
        user.tempBasket[0].tempBasketProducts &&
        user.tempBasket[0].tempBasketProducts.length > 0
      ) {
        const validProducts = user.tempBasket[0].tempBasketProducts.filter(
          (product) =>
            product.productId && (product.productId || product.productQuantity)
        );

        const formattedProducts = validProducts.map((product) => ({
          productId: product.productId,
          productQuantity: product.productQuantity,
        }));

        if (formattedProducts.length > 0) {
          localStorage.setItem('products', JSON.stringify(formattedProducts));
          setConfirmBasketProducts(formattedProducts);
        }
      }
    }
  }, [user, isLoggedIn]);

  const languages = ['', '/fr', '/en'];
  const routes = [
    { path: '/', element: <CategoriesPage /> },
    { path: '/team', element: <TeamPage /> },
    { path: '/gallery', element: <GalleryPage /> },
    { path: '/category/:id', element: <CategoryPage /> },
    { path: '/product/:id', element: <ProductPage /> },
    { path: '/producers', element: <ProducersPage /> },
    { path: '/producer/:id', element: <ProducerPage /> },
    { path: '/login', element: <LoginPage /> },
    { path: '/account/:param', element: <AccountPage /> },
    { path: '/basket', element: <BasketPage /> },
    { path: '/billingaddress', element: <BillingAddressPage /> },
    { path: '/shipping', element: <ShippingPage /> },
    { path: '/checkout', element: <CheckoutPage /> },
    { path: '/address', element: <AddressPage /> },
    { path: '/checkout-complete', element: <CheckoutComplete /> },
    { path: '/verify-email/:token', element: <VerifyEmailPage /> },
    { path: '/forgot-pwd', element: <ForgotPasswordPage /> },
    { path: '/reset/:id', element: <ResetPasswordPage /> },
    { path: '/signup-ok', element: <SignUpOkay /> },
    { path: '/conditions', element: <CgvPage /> },
    { path: '/privacy', element: <PrivacyPage /> },
    { path: '/legalnotice', element: <LegalNoticePage /> },
    { path: '/contact', element: <ContactPage /> },
  ];

  return (
    <HelmetProvider>
      <IntlProvider
        locale={locale}
        messages={messages}
        onError={(err) => {
          if (err.code === 'MISSING_TRANSLATION') {
            // console.warn(`Missing translation`);
            return;
          }
          throw err;
        }}
      >
        <AuthContext.Provider
          value={{
            isLoggedIn,
            setIsLoggedIn,
            products,
            setProducts,
            categories,
            setCategories,
            producers,
            setProducers,
            locale,
            setLocale,
            messages,
            socket,
            setSocket,
            user,
            setUser,
            shippingCost,
            setShippingCost,
            shipping,
            setShipping,
            shippingId,
            setShippingId,
            confirmBasketProducts,
            setConfirmBasketProducts,
            shippingAddresses,
            setShippingAddresses,
            billingAddresses,
            setBillingAddresses,
            chosenShippingAddress,
            setChosenShippingAddress,
            chosenBillingAddress,
            setChosenBillingAddress,
            taxes,
            basketTaxes,
            setBasketTaxes,
            promoCode,
            setPromoCode,
            performanceCookies,
            appliedPromoCode,
            setAppliedPromoCode,
            units,
            orders,
            setOrders,
            freeDeliveryAmount,
            setFreeDeliveryAmount,
            freeDelivery,
            setFreeDelivery,
            alcohol,
            setAlcohol,
            certifiedLegal,
            setCertifiedLegal,
            cartIconRef,
          }}
        >
          <div className="app">
            <Banner />
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                classNames="slide"
                timeout={500}
              >
                <Routes location={location}>
                  {routes.map(({ path, element }) =>
                    languages.map((lang) => {
                      const langPath =
                        lang === ''
                          ? path
                          : `${lang}${path === '/' ? '' : path}`;
                      return (
                        <Route
                          key={langPath}
                          path={langPath}
                          element={element}
                        />
                      );
                    })
                  )}
                  <Route
                    path="*"
                    element={<NotFoundPage />}
                  />
                </Routes>
              </CSSTransition>
            </TransitionGroup>
            {showCookieBanner && (
              <Modal
                isOpen={showCookieBanner}
                onClose={() => setShowCookieBanner(false)}
              >
                <CookieConsent onAccept={handleCookieAcceptance} />
              </Modal>
            )}
            <Footer />
          </div>
          <div className="background"></div>
        </AuthContext.Provider>
      </IntlProvider>
    </HelmetProvider>
  );
}

export default App;
