import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../App';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import getCurrentBasketProducts from '../basketcomponents/getcurrentbasketfunction';
import roundToTwo from './roundtotwo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

export default function GoToBasketLine() {
  const [basketExists, setBasketExists] = useState(false);
  const [freeDeliveryDifference, setFreeDeliveryDifference] = useState(0);
  const {
    locale,
    products,
    taxes,
    confirmBasketProducts,
    freeDelivery,
    freeDeliveryAmount,
    setFreeDelivery,
  } = useContext(AuthContext);
  const basketProducts = getCurrentBasketProducts(locale, products, taxes);
  console.log(basketProducts);
  const grandTotal =
    basketProducts.length > 0
      ? basketProducts.reduce(
          (total, product) => total + parseFloat(product.totalPrice),
          0
        )
      : 0;

  useEffect(() => {
    setFreeDelivery(grandTotal >= freeDeliveryAmount);
    setFreeDeliveryDifference(roundToTwo(freeDeliveryAmount - grandTotal));
  }, [confirmBasketProducts]);

  useEffect(() => {
    const localProducts = JSON.parse(localStorage.getItem('products'));
    if (localProducts !== null && localProducts.length > 0) {
      const totalQuantity = localProducts.reduce(
        (acc, product) => acc + product.productQuantity,
        0
      );

      setBasketExists(true);
    } else {
      setBasketExists(false);
    }
  }, [confirmBasketProducts]);
  return basketExists ? (
    <div className="go-to-basket-prompt ">
      <div className="banner-go-to-basket">
        {' '}
        <p>
          {' '}
          <Link
            to={'/basket'}
            className="go-to-basket-link"
          >
            <FormattedMessage
              id={'gallery.checkbasket'}
              defaultMessage={'Go to Cart '}
            />
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </p>
      </div>
      <div className="banner-freedelivery">
        {freeDelivery ? (
          <p>
            <FormattedMessage
              id="basket.freedelivery"
              defaultMessage="Free Delivery!"
            />
          </p>
        ) : (
          <p>
            <FormattedMessage
              id="basket.freedeliverymissing"
              defaultMessage="Free Delivery : Add {differenceamount}€"
              values={{ differenceamount: freeDeliveryDifference }}
            />
          </p>
        )}
      </div>{' '}
    </div>
  ) : (
    ''
  );
}
