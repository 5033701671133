import { FILES_URL } from '../../data/constants';

const getCurrentBasketProducts = (locale, products, taxes) => {
  const basketProducts = JSON.parse(localStorage.getItem('products')) || [];
  if (products.length > 0 && taxes.length > 0) {
    return basketProducts
      .map((basketItem) => {
        const productDetails = products.find(
          (product) => product._id === basketItem.productId
        );
        if (productDetails) {
          const taxDetails = taxes.find(
            (tax) => tax.title === productDetails.tax_code
          );
          const taxRate = taxDetails ? taxDetails.percent : 0;
          const taxAmount =
            productDetails.price *
            basketItem.productQuantity *
            (taxRate / (1 + taxRate));

          return {
            ...basketItem,
            name: productDetails[`title_${locale}`],
            stripe_id: productDetails[`stripe_id_${locale}`],
            unitPrice: productDetails.price,
            totalPrice: productDetails.price * basketItem.productQuantity,
            taxAmount: taxAmount.toFixed(2),
            taxRate: taxRate,
            stock: productDetails.stock,
            alcohol: productDetails.alcohol || false,
            img: `${FILES_URL}/${productDetails.images[0].imgUrl}`,
            imgDesc: productDetails.images[0].imgDesc,
          };
        }
      })
      .filter((item) => item !== null);
  } else {
    return [];
  }
};

export default getCurrentBasketProducts;
