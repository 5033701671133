import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../App';
import { FormattedMessage, useIntl } from 'react-intl';
import AddProductBasket from '../basketcomponents/addbasketcomponent';
import RemoveProductBasket from '../basketcomponents/removebasketcomponent';
import { API_ROUTES } from '../../data/constants';
import removeProductCompletely from './removeitembasket';
import getCurrentBasketProducts from './getcurrentbasketfunction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../common/modale';
import {
  faChevronDown,
  faChevronUp,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import ProductComponents from '../productcomponents/productcomponent';
import ScrollToTop from '../common/scrolltotop';
import roundToTwo from '../common/roundtotwo';
import sendKeyEvent from '../googleanalytics/keyeventscomponent';

export default function BasketComponent() {
  const [productQuantities, setProductQuantities] = useState([]);
  const [formData, setFormData] = useState({ promo: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [empty, setEmpty] = useState(false);
  const [freeDeliveryDifference, setFreeDeliveryDifference] = useState(0);
  const [productId, setProductId] = useState('');
  const [open, setOpen] = useState(false);
  const intl = useIntl();
  const {
    products,
    locale,
    setConfirmBasketProducts,
    taxes,
    basketTaxes,
    setBasketTaxes,
    setPromoCode,
    promoCode = {},
    user,
    setAppliedPromoCode,
    setFreeDelivery,
    freeDelivery,
    freeDeliveryAmount,
    alcohol,
    setAlcohol,
    certifiedLegal,
    setCertifiedLegal,
    socket,
  } = useContext(AuthContext);

  // Helper function to generate localized paths
  const getLocalizedPath = (path) => {
    if (locale === '') return path;
    if (path === '/') return `/${locale}`;
    return `/${locale}${path}`;
  };

  const updateProductQuantities = () => {
    if (locale && products && taxes) {
      const updatedQuantities = getCurrentBasketProducts(
        locale,
        products,
        taxes
      );
      setProductQuantities(updatedQuantities);
      setConfirmBasketProducts(updatedQuantities);
    }
  };

  const applyPromoCodeToBasket = (products, promoCode) => {
    let totalDiscount = 0;
    let updatedProducts = [...products];

    if (promoCode.discountType === 'fixed') {
      // Calculate the total applicable amount
      let applicableTotal = products.reduce((total, product) => {
        if (
          promoCode.applicableProducts?.length === 0 ||
          promoCode.applicableProducts?.some(
            (promoProduct) => promoProduct.stripe_id === product.stripe_id
          )
        ) {
          return total + product.totalPrice;
        }
        return total;
      }, 0);

      // Apply the discount to the applicable total
      totalDiscount = Math.min(promoCode.discountAmount, applicableTotal);

      // Distribute the discount proportionally across applicable products
      updatedProducts = products.map((product) => {
        let discount = 0;
        if (
          promoCode.applicableProducts?.length === 0 ||
          promoCode.applicableProducts?.some(
            (promoProduct) => promoProduct.stripe_id === product.stripe_id
          )
        ) {
          const proportion = product.totalPrice / applicableTotal;
          discount = proportion * totalDiscount;
        }

        return {
          ...product,
          discount,
          totalPrice: (product.totalPrice - discount).toFixed(2),
          unitPrice: (
            (product.totalPrice - discount) /
            product.productQuantity
          ).toFixed(2),
          taxAmount: (
            (product.totalPrice - discount) *
            product.taxRate
          ).toFixed(2),
        };
      });
    } else {
      // Percent discount logic
      updatedProducts = products.map((product) => {
        let discount = 0;
        if (
          promoCode.applicableProducts?.length === 0 ||
          promoCode.applicableProducts?.some(
            (promoProduct) => promoProduct.stripe_id === product.stripe_id
          )
        ) {
          if (promoCode.discountType === 'percent') {
            discount = (product.totalPrice * promoCode.discountAmount) / 100;
          }
          discount = Math.min(discount, product.totalPrice);
          totalDiscount += discount;
        }
        return {
          ...product,
          discount,
          totalPrice: product.totalPrice - discount,
          unitPrice: (
            (product.totalPrice - discount) /
            product.productQuantity
          ).toFixed(2),
          taxAmount: (
            (product.totalPrice - discount) *
            product.taxRate
          ).toFixed(2),
        };
      });
    }

    return { updatedProducts, totalDiscount };
  };

  const basketProducts = getCurrentBasketProducts(locale, products, taxes);

  const grandTotal = basketProducts.reduce(
    (total, product) => total + parseFloat(product.totalPrice),
    0
  );

  const { updatedProducts, totalDiscount } =
    grandTotal >= promoCode.minimumPurchase
      ? applyPromoCodeToBasket(basketProducts, promoCode)
      : { updatedProducts: basketProducts, totalDiscount: 0 };

  const totalTax = updatedProducts.reduce(
    (total, product) => total + parseFloat(product.taxAmount),
    0
  );
  const totalAfterDiscount = updatedProducts.reduce(
    (total, product) => total + parseFloat(product.totalPrice),
    0
  );

  useEffect(() => {
    setEmpty(basketProducts.length === 0);
    if (typeof totalTax !== 'number') {
      throw new Error('totalTax must be a number');
    }

    // Round totalTax to two decimal places
    const totalTaxRounded = Math.round(totalTax * 100) / 100;

    setBasketTaxes(totalTaxRounded);

    const containsAlcohol = basketProducts.some(
      (item) => item.alcohol === true
    );
    setAlcohol(containsAlcohol);
  }, [basketProducts, totalTax]);

  useEffect(() => {
    const updatedProducts = getCurrentBasketProducts(locale, products, taxes);
    setProductQuantities(updatedProducts);
    setConfirmBasketProducts(updatedProducts);
  }, [products]);

  useEffect(() => {
    setAppliedPromoCode(grandTotal >= promoCode.minimumPurchase);
    setFreeDelivery(grandTotal >= freeDeliveryAmount);
    setFreeDeliveryDifference(roundToTwo(freeDeliveryAmount - grandTotal));
  }, [grandTotal, promoCode]);

  const verifyPromoCode = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(API_ROUTES.GET_PROMO_CODE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include authentication token if required
        },
        body: JSON.stringify({
          locale: locale,
          promo: formData.promo,
        }),
      });

      const data = await response.json();
      if (response.status === 415) {
        setErrorMessage(data.message);
        sendKeyEvent({
          eventCategory: 'Error',
          eventAction: 'Enter Promo',
          eventLabel: data.message,
          eventValue: response.status,
        });
      } else if (response.status === 404) {
        setErrorMessage(
          <FormattedMessage
            id="basket.promocodeinactive"
            defaultMessage="This code is inactive."
          />
        );
        sendKeyEvent({
          eventCategory: 'Error',
          eventAction: 'Enter Promo',
          eventLabel: data.message,
          eventValue: response.status,
        });
      } else if (response.status === 500) {
        setErrorMessage(
          <FormattedMessage
            id="delete.error"
            defaultMessage="Something terrible happened"
          />
        );
        sendKeyEvent({
          eventCategory: 'Error',
          eventAction: 'Enter Promo',
          eventLabel: data.message,
          eventValue: response.status,
        });
      } else if (data.active === false) {
        setErrorMessage(
          <FormattedMessage
            id="basket.promocodeinactive"
            defaultMessage="This code is inactive."
          />
        );
        sendKeyEvent({
          eventCategory: 'Error',
          eventAction: 'Enter Promo',
          eventLabel: 'Old Code',
        });
      } else {
        setPromoCode(data);
        sendKeyEvent({
          eventCategory: 'Promo',
          eventAction: 'Enter Promo',
          eventLabel: 'Success',
          eventValue: formData.promo,
        });
      }

      setFormData({
        promo: '',
      });
    } catch (error) {
      setErrorMessage(
        <FormattedMessage
          id="basket.errorverifying"
          defaultMessage="Error verifying promo code."
        />
      );
    }
    throw new Error('Error verifying promo code:', error);
  };

  const openProductModal = (e, product_id) => {
    e.preventDefault();
    setProductId(product_id);
    setOpen(true);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const decreaseQuantity = (
    productId,
    productName,
    updateProductQuantities,
    socket,
    quantity
  ) => {
    if (quantity > 1) {
      return RemoveProductBasket(productId, updateProductQuantities, socket);
    } else if (quantity === 1) {
      const confirmationMessage = intl.formatMessage(
        {
          id: 'basket.confirmRemoveProduct',
          defaultMessage:
            'Are you sure you want to remove {productName} from your basket?',
        },
        { productName }
      );
      const userConfirmed = window.confirm(confirmationMessage);
      if (userConfirmed) {
        return RemoveProductBasket(productId, updateProductQuantities, socket);
      }
    }
  };

  function generateBasketRow(product, index) {
    const productImg = product.img;
    const maxLength = 40; // Maximum allowed length including "..."
    const productName =
      product.name.length > maxLength
        ? product.name.slice(0, maxLength - 3) + '...'
        : product.name;
    return (
      <div
        className="basket-row"
        key={index}
      >
        <div className="basket-cell-groups">
          <div
            className="basket-cell product-cell"
            onClick={(e) => openProductModal(e, product.productId)}
          >
            <img
              className="basket-img remove-mobile-display"
              src={productImg}
              alt={productName}
              loading="lazy"
            />

            <h3 className="basket-product-title">{productName}</h3>
          </div>
          <div className="basket-cell remove-mobile-display ">
            <p>
              <FormattedMessage
                id="basket.unitpriceamount"
                defaultMessage="{price}€ incl. VAT"
                values={{ price: product.unitPrice }}
              />
              <span className="mobile-display-add">
                <FormattedMessage
                  id="basket.unit"
                  defaultMessage="/Unit"
                />
              </span>
            </p>
          </div>
        </div>
        <div className="basket-cell">
          <span className="product-quantity">{product.productQuantity}</span>
          <div className="basket-add-remove-buttons-div">
            <button
              className="quantity-btn decrease product-add-remove-button basket-add-remove-buttons"
              onClick={() =>
                decreaseQuantity(
                  product.productId,
                  productName,
                  updateProductQuantities,
                  socket,
                  product.productQuantity
                )
              }
              disabled={product.productQuantity <= 0}
            >
              <span className="add-remove-button-content">
                <FontAwesomeIcon icon={faChevronDown} />
              </span>
            </button>
            <button
              className="quantity-btn increase product-add-remove-button basket-add-remove-buttons"
              onClick={() =>
                AddProductBasket(
                  product.productId,
                  updateProductQuantities,
                  socket
                )
              }
              disabled={product.productQuantity >= product.stock}
            >
              <span className="add-remove-button-content">
                <FontAwesomeIcon icon={faChevronUp} />
              </span>
            </button>
          </div>
        </div>
        <div className="basket-cell-groups">
          <div className="basket-cell">
            <p>
              <FormattedMessage
                id="basket.unitpriceamount"
                defaultMessage="{price}€ incl. VAT"
                values={{ price: parseFloat(product.totalPrice).toFixed(2) }}
              />
            </p>
          </div>
        </div>
        <div className="basket-cell remove-mobile-display ">
          <button
            className="remove-btn basket-submit-btn"
            onClick={() =>
              removeProductCompletely(
                product.productId,
                updateProductQuantities,
                socket
              )
            }
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </div>
    );
  }

  ScrollToTop();
  return empty ? (
    <Link to={getLocalizedPath('/gallery')}>
      <FormattedMessage
        id="basket.addproduct"
        defaultMessage="Add products to the basket!"
      />
    </Link>
  ) : (
    <div className="basket-container">
      <div className="basket-grid">
        <div className="basket-header">
          <div className="basket-cell-groups">
            <div className="basket-cell basket-header-product ">
              <h2>
                <FormattedMessage
                  id="basket.productname"
                  defaultMessage="Product"
                />
              </h2>
            </div>
            <div className="basket-cell basket-header-unit-price remove-mobile-display ">
              <h2>
                <FormattedMessage
                  id="basket.unitprice"
                  defaultMessage="Unit Price"
                />
              </h2>
            </div>
          </div>
          <div className="basket-cell basket-header-quantity">
            <h2>
              <FormattedMessage
                id="basket.quantity"
                defaultMessage="Quantity"
              />
            </h2>
          </div>
          <div className="basket-cell-groups">
            <div className="basket-cell basket-header-total-price ">
              <h2>
                <FormattedMessage
                  id="basket.totalprice"
                  defaultMessage="Total Price"
                />
              </h2>
            </div>
          </div>
          <div className="basket-cell basket-header-remove remove-mobile-display  ">
            <h2>
              <FormattedMessage
                id="basket.remove"
                defaultMessage="Remove"
              />
            </h2>
          </div>
        </div>
        {updatedProducts.map((product, index) =>
          generateBasketRow(product, index)
        )}
      </div>
      <div className="promo-total-basket-container">
        <div className="free-delivery-div">
          {freeDelivery ? (
            <p className="free-delivery">
              <FormattedMessage
                id="basket.freedelivery"
                defaultMessage="Free Delivery!"
              />
            </p>
          ) : (
            <p className="not-free-delivery">
              <FormattedMessage
                id="basket.freedeliverymissing"
                defaultMessage="Add {differenceamount}€ to get free delivery!"
                values={{ differenceamount: freeDeliveryDifference }}
              />
            </p>
          )}
        </div>
        <div className="promo-code-selection form-container">
          {promoCode?.name ? (
            <>
              <h3 className="promo-title">
                <FormattedMessage
                  id="basket.promocode"
                  defaultMessage="Promo Code:"
                />{' '}
                {promoCode.name}
              </h3>
              <span>
                <FormattedMessage
                  id="basket.discount"
                  defaultMessage="Discount of "
                />{' '}
                {promoCode.discountAmount}
                {promoCode.discountType === 'percent' ? '% ' : '€ '}
                
              </span>

              {promoCode.minimumPurchase ? (
                <span>
                  <FormattedMessage
                    id="basket.minpurchase"
                    defaultMessage="For a minimum purchase amount of:"
                  />{' '}
                  <FormattedMessage
                    id="basket.unitpriceamount"
                    defaultMessage="{price}€ incl. VAT"
                    values={{ price: promoCode.minimumPurchase }}
                  />
                </span>
              ) : (
                ''
              )}

              <button
                className="promo-code-submit-button"
                onClick={() => setPromoCode({})}
              >
                <FormattedMessage
                  id="basket.cancelcode"
                  defaultMessage="Try another code"
                />
              </button>
            </>
          ) : (
            <form
              className="form-group"
              onSubmit={verifyPromoCode}
            >
              <label
                htmlFor="promo"
                className="form-label promo-label"
              >
                <FormattedMessage
                  id="basket.promolabel"
                  defaultMessage="Promo Code"
                />
              </label>
              <input
                name="promo"
                type="text"
                value={formData.promo}
                onChange={(e) => handleChange(e)}
              ></input>{' '}
              <button
                className="promo-code-submit-button"
                type="submit"
              >
                <FormattedMessage
                  id="basket.submitpromo"
                  defaultMessage="Submit Code"
                />
              </button>
              <span>{errorMessage}</span>
            </form>
          )}
        </div>
        <div className="basket-total remove-mobile-display">
          <div className="total-tax">
            <h2>
              <FormattedMessage
                id="basket.totaltax"
                defaultMessage="Total Tax"
              />
              {basketTaxes > 0 ? `${basketTaxes}€` : 'loading'}
            </h2>
          </div>
          <div className="total-total ">
            <h2>
              <FormattedMessage
                id="basket.grandtotal"
                defaultMessage="Grand Total "
              />
              {parseFloat(totalAfterDiscount).toFixed(2)}€
            </h2>
            {totalDiscount > 0 ? (
              <p>
                <FormattedMessage
                  id="basket.savings"
                  defaultMessage="You save {amount}€"
                  values={{ amount: totalDiscount.toFixed(2) }}
                />
              </p>
            ) : (
              ''
            )}
          </div>
        </div>
        {alcohol ? (
          <div className="alcohol-div remove-mobile-display">
            <label htmlFor="certified-legal">
              <FormattedMessage
                id="basket.certify"
                defaultMessage="I am 18 years old or older"
              />
            </label>
            <input
              type="checkbox"
              name="certified-legal"
              checked={certifiedLegal}
              onChange={(e) => setCertifiedLegal(e.target.checked)}
            ></input>
          </div>
        ) : (
          ''
        )}
        {alcohol && !certifiedLegal ? (
          <button
            disabled={true}
            className="basket-submit-btn remove-mobile-display "
          >
            <FormattedMessage
              id="basket.certifyfirst"
              defaultMessage="Your basket contains Alcohol, please certify your age"
            />
          </button>
        ) : (
          <Link
            to={getLocalizedPath('/address')}
            className="basket-submit-btn remove-mobile-display"
            onClick={(e) =>
              sendKeyEvent({
                eventCategory: 'E-Commerce',
                eventAction: 'Validate Cart',
                eventValue: totalAfterDiscount || '',
              })
            }
          >
            <FormattedMessage
              id="basket.validation"
              defaultMessage="Go to Checkout"
            />{' '}
          </Link>
        )}
      </div>
      <div className="mobile-basket-total-div">
        <div className="basket-total ">
          <div className="total-total ">
            <h2>
              <FormattedMessage
                id="basket.grandtotal"
                defaultMessage="Grand Total "
              />
              {parseFloat(totalAfterDiscount).toFixed(2)}€
            </h2>
            {totalDiscount > 0 ? (
              <p>
                <FormattedMessage
                  id="basket.savings"
                  defaultMessage="You save {amount}€"
                  values={{ amount: totalDiscount.toFixed(2) }}
                />
              </p>
            ) : (
              ''
            )}
          </div>
        </div>
        {alcohol ? (
          <div
            className={`alcohol-div ${certifiedLegal ? 'hidden-alcohol' : ''}`}
          >
            <label htmlFor="certified-legal">
              <FormattedMessage
                id="basket.certify"
                defaultMessage="I am 18 years old or older"
              />
            </label>
            <input
              type="checkbox"
              name="certified-legal"
              checked={certifiedLegal}
              className="certified-legal-checkbox"
              onChange={(e) => setCertifiedLegal(e.target.checked)}
            ></input>
          </div>
        ) : (
          ''
        )}
        {alcohol && !certifiedLegal ? (
          <button
            disabled={true}
            className="basket-submit-btn"
          >
            <FormattedMessage
              id="basket.certifyfirst"
              defaultMessage="Your basket contains Alcohol, please certify your age"
            />
          </button>
        ) : (
          <Link
            to={getLocalizedPath('/address')}
            className="basket-submit-btn"
          >
            <FormattedMessage
              id="basket.validation"
              defaultMessage="Go to Checkout"
            />{' '}
          </Link>
        )}
      </div>
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
      >
        <ProductComponents productId={productId} />
      </Modal>
    </div>
  );
}
